import '@/modules/Diagram.jQueryGlobal.js';
import { DiagramHasJS } from '@/modules/Diagram.hasJS.js';
import { DiagramAnalytics } from '@/modules/Diagram.Analytics.js'
import fitvids from '@/plugins/fitvids.js';
import DiagramBreakpoints from '@/modules/Diagram.Breakpoints.js';

DiagramHasJS();

if (typeof pageTracker == "undefined") {
    var pageTracker = {};
}

function customGAEvent(category, action, label) {
    try {
        pageTracker._trackEvent(category, action, label);
    } catch (e) {
        // console.log(e);
    }
}

// Items that can be processed before/after images/css complete
$(document).ready(async function ($) {
    await DiagramAnalytics();
    await fitvids();

    if ($('.alerts-wrapper').length) {
        const { DiagramAlertBanner } = await import('@/modules/Diagram.AlertBanner.js');
        await DiagramAlertBanner();
    }

    if ($('.js-main-nav').length) {
        const { DiagramNavDesktop } = await import('@/modules/Diagram.NavDesktop.js');
        await DiagramNavDesktop();
    }

    if ($('.js-mobile-nav').length) {
        const { DiagramNavMobile } = await import('@/modules/Diagram.NavMobile.js');
        await DiagramNavMobile();
    }

    if ($('.js-sticky-header').length) {
        const { DiagramStickyHeader } = await import('@/modules/Diagram.StickyHeader.js');
        await DiagramStickyHeader();
    }

    if ($('.js-overflow-scroll').length) {
        const { Scrollbar } = await import('@/plugins/jquery-scrollbar.js');
        await Scrollbar();
    }

    if ($('.js-accordion').length) {
        const { DiagramAccordions } = await import('@/modules/Diagram.Accordions.js');
        await DiagramAccordions();
    }

    if ($('.js-accordion-transcript').length) {
        const { DiagramVideoTranscript } = await import('@/modules/Diagram.VideoTranscript.js');
        await DiagramVideoTranscript();
    }

    if ($('table, .scrolling-table').length) {
        const { DiagramTables } = await import('@/modules/Diagram.Tables.js');
        await DiagramTables();
    }

    if ($('.js-scroll-anchor').length) {
        const { DiagramSmoothAnchors } = await import('@/modules/Diagram.SmoothAnchors.js');
        await DiagramSmoothAnchors();
    }

    if ($('.footer-menu').length) {
        const { DiagramFooter } = await import('@/modules/Diagram.Footer.js');
        await DiagramFooter();
    }

    if ($('.section-nav').length) {
        const { DiagramSectionNavigation } = await import('@/modules/Diagram.SectionNav.js');
        await DiagramSectionNavigation();
    }

    if ($('.js-print').length) {
        const { DiagramPrint } = await import('@/modules/Diagram.Print.js');
        await DiagramPrint();
    }

    if ($('.pagination').length) {
        const { DiagramPagination } = await import('@/modules/Diagram.Pagination.js');
        await DiagramPagination();
    }

    if ($('.js-loadmore').length) {
        const { DiagramLoadMore } = await import('@/modules/Diagram.LoadMore.js');
        await DiagramLoadMore();
    }

    if ($('.search-form').length) {
        const { DiagramSearchFormBlock } = await import('@/modules/Diagram.SearchFormBlock.js');
        await DiagramSearchFormBlock();
    }

    if ($('.js-select-to-query').length) {
        const { DiagramSelectToQuery } = await import('@/modules/Diagram.SelectToQuery.js');
        await DiagramSelectToQuery();
    }

    if ($('.js-social-share').length) {
        const { DiagramSocialShare } = await import('@/modules/Diagram.SocialShare.js');
        await DiagramSocialShare();
    }

    if ($('.fancybox, [data-fancybox]').length) {
        const { DiagramFancybox } = await import('@/modules/Diagram.Fancybox.js');
        await DiagramFancybox();
    }

    if ($('.js-lightbox-cta').length) {
        const { DiagramLightboxCTA } = await import('@/modules/Diagram.LightboxCTA.js');
        await DiagramLightboxCTA();
    }

    if ($('.js-gallery').length) {
        const { DiagramPhotoGallery } = await import('@/modules/Diagram.PhotoGallery.js');
        await DiagramPhotoGallery();
    }

    if ($('.js-google-map').length) {
        const { DiagramGoogleMap } = await import('@/modules/Diagram.GoogleMap.js');
        await DiagramGoogleMap();
    }

    if ($('.js-language-switcher').length) {
        const { DiagramLanguageSwitcher } = await import('@/modules/Diagram.LanguageSwitcher.js');
        await DiagramLanguageSwitcher();
    }

    if ($('.js-blog-article-filter').length) {
        const { DiagramBlogArticleFilter } = await import('@/modules/Diagram.Blog.ArticleFilter.js');
        await DiagramBlogArticleFilter();
    }

    await DiagramBreakpoints();

});


// This occurs after the page is done loading.
$(window).on("load", function () {
    if (typeof _gat != "undefined") {
        pageTracker = _gat._createTracker('UA-11858386-2');
    }

    $('[data-ga-track]').each(function () {
        var $this = $(this);
        if ($this.attr('data-ga-category') != undefined && $this.attr('data-ga-event') != undefined && $this.attr('data-ga-label') != undefined) {
            customGAEvent($this.attr('data-ga-category'), $this.attr('data-ga-event'), $this.attr('data-ga-label'));
        }
    });
});